<template>
  <div class="steps-container">
    <div class="center-container">
      <div class="progress-xtra justify-content-center">
        <ProgressBar></ProgressBar>
      </div>
      <template v-if="is_loading">
        <Preloader></Preloader>
      </template>
      <template v-else>
        <header>
          <div class="steps-address">
            <h2 class="steps-address-title">{{ bbl_basic_data.shortAddress }}</h2>
            <h6 class="steps-address-subtitle">
              Block: {{ bbl_basic_data.block }} |
              Lot: {{ bbl_basic_data.lot }} |
              Zoning: {{ bbl_data.zoning }} |
              Lot Area: {{ num(bbl_data.landArea) }} SF
              <template v-if="area_custom">
                | <b>Lot Area Manual: {{ num(area_custom) }} SF</b>
              </template>
            </h6>
          </div>
        </header>
        <div class="wrp">
          <slot></slot>
        </div>
      </template>
    </div>
    <b-modal id="modalPopover" title="Modal with Popover" size="xl" hide-footer hide-header>
      <GoogleMap></GoogleMap>
    </b-modal>
  </div>
</template>

<script>
import GoogleMap from '@/components/GoogleMap'
import ProgressBar from '../components/ProgressBar.vue';
import { mapGetters } from "vuex";

import { number } from "./../utils";
import Preloader from '@/components/Preloader.vue';

export default {
  name: "StepsContainer",
  components: {
    GoogleMap,
    ProgressBar,
    Preloader
  },
  computed: {
    ...mapGetters({
      bbl_basic_data: 'get_BBLBasicData',
      bbl_data: 'get_BBLData',
      bbl_basic_data_status: 'get_BBLBasicData_status',
      bbl_data_status: 'get_BBLData_status',
      bblId: 'get_bblId',
      area_custom: 'get_area_custom',
    }),
    is_loading() {
      // console.log('is_loading', this.bbl_basic_data_status.is_loading || this.bbl_data_status.is_loading, this.bbl_basic_data_status.is_loading, this.bbl_data_status.is_loading)
      return this.bbl_basic_data_status.is_loading || this.bbl_data_status.is_loading
    },
  },
  beforeCreate() {
    // console.log('beforeCreate StepsContainer.vue', Date.now())
  },
  beforeMount() {
    // console.log('beforeMount StepsContainer.vue', Date.now())
  },
  mounted() {
    // console.log('mounted StepsContainer.vue', Date.now())
  },
  created() {
    // console.log('created StepsContainer.vue', Date.now())

  },
  methods: {
    num: number,
  }
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";

.steps-container {
  background: $common-bg;
  min-height: calc(100vh - 73px);
}

.wrp {
  padding-bottom: 24px;
}

.center-container {
  width: 872px;
  margin: 0 auto;
}

header {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  padding: 24px 0;
}

.steps-address {
  -webkit-order: 0;
  -ms-flex-order: 0;
  order: 0;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-align-self: auto;
  -ms-flex-item-align: auto;
  align-self: auto;
}

.steps-address-title {
  text-align: center;
  color: $font-black;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: -0.41px;
  line-height: 49px;
}

.steps-address-subtitle {
  text-align: center;
  color: $font-gray;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 0;
}

.steps-photos,
.steps-map {
  height: 80px;
  width: 80px;
  border-radius: 10px;
  background-color: #FFFFFF;
  border: 1px solid #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(26, 41, 60, 0.15);
  margin-left: 12px;
  padding: 3px;
}

.steps-photos img,
.steps-map img {
  width: 100%;
  display: block;
  border-radius: 8px;
  overflow: hidden;
}

.steps-map:hover,
.steps-map:active,
.steps-map:focus-within,
.steps-map:focus-visible,
.steps-map:focus {
  height: 80px;
  width: 80px;
  border-radius: 10px;
  background-color: #FFFFFF !important;
  border: 1px solid #FFFFFF !important;
  box-shadow: 0 2px 6px 0 rgba(26, 41, 60, 0.15) !important;
  margin-left: 12px;
  padding: 4px;
}

.progress-xtra {
  display: none;
}

@media screen and (max-width: 768px) {

  .center-container {
    width: auto;
  }

  header {
    border-bottom: none;
  }
}

@media screen and (max-width: 420px) {
  .steps-address {
    padding: 16px;
    width: 390px;
    height: auto;
  }

  .steps-address-title {
    font-size: 26px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 36px;

  }

  .progress-xtra {
    display: flex;
    margin-top: 20px;
  }

  .center-container {
    width: auto;
  }

  header {
    border-bottom: none;
    padding: 0;
  }
}

@media screen and (max-width: 375px) {
  .steps-address {
    padding: 16px;
    width: 375px;
  }

  .steps-address-title {
    font-size: 23px;
    font-weight: 600;
    letter-spacing: -0.3px;
    line-height: 28px;

  }

  .progress-xtra {
    display: flex;
    margin-top: 20px;
  }

  .center-container {
    width: auto;
  }

  header {
    border-bottom: none;
  }
}

@media screen and (max-width: 320px) {
  .steps-address {
    padding: 16px;
    width: 320px;
  }

  .steps-address-title {

    margin-left: auto;
    margin-right: auto;
  }
}
</style>