<template>
  <div class="wrap-header ">
    <div class="hr d-flex">
      <img class="main-logo-img mr-auto" @click="refresh" src="../assets/logo_regtime.svg" alt="">
      <!-- <a class="anchor-link" href="#" @click.prevent="scrollTo('how_it_work')">How it works</a>
      <a class="anchor-link" href="#"  @click.prevent="scrollTo('to_find_out')">Contact us</a> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  methods: {
    refresh(){
      window.location.reload()
    },
    scrollTo(query){
      const el = document.getElementById(query);
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  }
}
</script>

<style scoped>
.wrap-header {
  width: 100%;
  height: 73px;
  margin: 0 auto;
  padding: 24px 60px 0;

  overflow: hidden;
}
.hr{
  border-bottom: 1px solid #E6E8FF;
  padding-bottom: 24px;
}
.main-logo-img {
  height: 24px;
  width: 112px;
  margin: 0;
  cursor: pointer;
}
.anchor-link {
  color: #104AB1;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-left: 32px;
}
.anchor-link:hover {
  text-decoration: none;
  color: #0A1250;

}
@media screen and (max-width: 768px) {
  .wrap-header {
    max-width: 1320px;
    height: 63px;
    margin: 0 auto;
    padding: 24px 24px;
    border-bottom: 1px solid  #E6E8FF;
    overflow: hidden;
  }
}
  @media screen and (max-width: 420px) {
    .anchor-link{
      display: none;
    }
    .wrap-header{
      justify-content: center;
      border-bottom: none;
      height: 40px;
      margin: 0 auto 6px;
      padding: 16px 0 0;
      overflow: hidden;
    }
    .main-logo-img{
      height: 24px;
      width: 112.5px;
      margin-left: auto;
      margin-right: auto;
    }
}
@media screen and (max-width: 375px) {
  .anchor-link{
    display: none;
  }
  .wrap-header{
    justify-content: center;
    border-bottom: none;
  }
  .main-logo-img{
    height: 24px;
    width: 112.5px;
    margin-left: auto;
    margin-right: auto;
  }
}

</style>
