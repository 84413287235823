<template>
  <div class="wrapper-tax-purpose mx-auto">
    <h4 class="tax-purpose-title">Select target building type</h4>
    <!-- <h5 class="tax-purpose-hint">Answers to these questions will help us make an accurate analysis.</h5> -->
    <div class="wrapper-button">
      <!-- <ButtonDefault @click="handleClickResult('condo')" text="Condo" /> -->
      <ButtonDefault :need_forward="false" btn-class="btn-clss" v-b-modal.sign-up-condo-modal text="Condo">
      </ButtonDefault>
      <ButtonDefault @click="handleClickResult('rental')" text="Rental" />
    </div>
    <SignUpCondoModal></SignUpCondoModal>
  </div>
</template>

<script>
import ButtonDefault from '@/components/ButtonDefault'
import SignUpCondoModal from '@/components/SignUpCondoModal.vue';
import { mapGetters } from "vuex";

export default {
  name: "LotTypeSelection",
  components:{
    ButtonDefault,
    SignUpCondoModal
  },
  data: () =>  ({
    specialZoning: ['R6', 'R7-1', 'R7-2', 'R8'],
  }),
  computed: {
    ...mapGetters({
      need_custom_streettype: 'get_need_streetType_custom'
    })
  },
  methods:{
    async handleClickResult(type){
      if (type === 'rental') {
        this.$router.push({ name: 'lot-street-type-selection' })
        // if zoning in R6, R7-1, R7-2, R8 and zoningStreetType == None or 'mix'
        if (this.need_custom_streettype) {
          this.$router.push({name :'lot-street-type-selection'})
        } else {
          this.$router.push({name :'build-date-selection'})
        }

        // this.$router.push({name :'build-date-selection'})
      }

    },
  }
}
</script >

<style lang=scss scoped>
@import "../scss/variables.scss";
.wrapper-tax-purpose {
  height: 184px;
  width: 100%;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(26,41,60,0.1);
  padding: 24px;
}
.tax-purpose-title {
  height: 33px;
  color: $font-black;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  width: 100%;
  margin-bottom: 36px;
}
.tax-purpose-hint{
  height: 19px;
  width: 100%;
  color: $font-gray;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 34px;
}
.wrapper-button {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.wrapper-button > button {
  margin-top: 0;

  margin-right: 16px;
}
.wrapper-button > button:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .tax-purpose-title {
    height: 30px;
  }
  .wrapper-tax-purpose {
    height: 177px;
    width: 720px;
    padding: 20px;
  }
  .tax-purpose-hint{
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 420px) {
  .tax-purpose-title {
    font-size: 19px;
    letter-spacing: 0;
    line-height: 28px;
    height: 56px;
    width: 252px;
    margin-right: auto;
    margin-left: auto;
  }
  .wrapper-tax-purpose {
    height: 318px;
    width: 390px;
    padding: 16px;
    border-radius: 0;
  }
  .tax-purpose-hint{
    height: 34px;
    width: 223px;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  .wrapper-button {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .btn-default{
    width: 358px;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 375px) {
  .wrapper-tax-purpose {
    height: 318px;
    width: 375px;
  }
  .btn-default{
    width: 343px;
  }
}
@media screen and (max-width: 320px) {
  .wrapper-tax-purpose {
    height: 318px;
    width: 320px;
  }
  .btn-default{
    width: 288px;
  }
}
</style>