export default {
    "bbleId": 184405,
    "boroughCode": null,
    "boroughName": null,
    "block": null,
    "lot": null,
    "lotArea": 25956.0,
    "lotAreaTotal": null,
    "zone": "R6",
    "density": "High",
    "far": 2.2,
    "farUAP": 3.9,
    "farWide": 3.0,
    "farUAPWide": 3.9,
    "avgTaxPerSf": null,
    "avBeforeConstruction": null,
    "options": [
        {
            "program": "none",
            "type": "regular",
            "maxResFloorArea": 57103.200000000004,
            "maxNumberOfResUnits": 83.97529411764707,
            "affHousingFloorArea": 0,
            "projNumberOfAffHousingUnitsPerc": 0,
            "projNumberOfAffHousingUnitsTotal": 0,
            "projNumberOfAffHousingUnitsSet": [],
            "projNumberOfFreeMarketUnits": 83.97529411764707,
            "postCompletionPropTax": 685238.4,
            "taxSavingsPerYear": 0
        }, {
            "program": "x485",
            "type": "regular",
            "maxResFloorArea": 57103.200000000004,
            "maxNumberOfResUnits": 83.97529411764707,
            "affHousingFloorArea": 11421,
            "projNumberOfAffHousingUnitsPerc": 0.2000063043752364,
            "projNumberOfAffHousingUnitsTotal": 17,
            "projNumberOfAffHousingUnitsSet": [],
            "projNumberOfFreeMarketUnits": 66.97529411764707,
            "postCompletionPropTax": 244069,
            "taxSavingsPerYear": 441169.4
        }, {
            "program": "uap",
            "type": "regular",
            "maxResFloorArea": 101228.4,
            "maxNumberOfResUnits": 148.86529411764704,
            "affHousingFloorArea": 44126,
            "projNumberOfAffHousingUnitsPerc": 0.4359053388179602,
            "projNumberOfAffHousingUnitsTotal": 65,
            "projNumberOfAffHousingUnitsSet": [
                {
                    "numberOfUnits": 13,
                    "ami": 40
                },
                {
                    "numberOfUnits": 45,
                    "ami": 60
                }
            ],
            "projNumberOfFreeMarketUnits": 83.86529411764704,
            "postCompletionPropTax": 244069,
            "taxSavingsPerYear": 970671.7999999998
        },
        {
            "program": "none",
            "type": "wide",
            "maxResFloorArea": 77868.0,
            "maxNumberOfResUnits": 114.51176470588236,
            "affHousingFloorArea": 0,
            "projNumberOfAffHousingUnitsPerc": 0,
            "projNumberOfAffHousingUnitsTotal": 0,
            "projNumberOfAffHousingUnitsSet": [],
            "projNumberOfFreeMarketUnits": 114.51176470588236,
            "postCompletionPropTax": 934416.0,
            "taxSavingsPerYear": 0
        },
        {
            "program": "x485",
            "type": "wide",
            "maxResFloorArea": 77868.0,
            "maxNumberOfResUnits": 114.51176470588236,
            "affHousingFloorArea": 15574,
            "projNumberOfAffHousingUnitsPerc": 0.2000051368983408,
            "projNumberOfAffHousingUnitsTotal": 23,
            "projNumberOfAffHousingUnitsSet": [],
            "projNumberOfFreeMarketUnits": 91.51176470588236,
            "postCompletionPropTax": 244069,
            "taxSavingsPerYear": 690347.0
        },
        {
            "program": "uap",
            "type": "wide",
            "maxResFloorArea": 101228.4,
            "maxNumberOfResUnits": 148.86529411764704,
            "affHousingFloorArea": 23361,
            "projNumberOfAffHousingUnitsPerc": 0.230775157959624,
            "projNumberOfAffHousingUnitsTotal": 35,
            "projNumberOfAffHousingUnitsSet": [
                {
                    "numberOfUnits": 7,
                    "ami": 40
                },
                {
                    "numberOfUnits": 24,
                    "ami": 60
                }
            ],
            "projNumberOfFreeMarketUnits": 113.86529411764704,
            "postCompletionPropTax": 244069,
            "taxSavingsPerYear": 970671.7999999998
        }

    ],
}

function buildOptionsMap(items, programs) {
    return items
        .filter(item => programs.includes(item.program))
        .reduce((acc, item) => {
        if (!acc[item.program]) acc[item.program] = {};
        if (!acc[item.program][item.type]) acc[item.program][item.type] = {};
        acc[item.program][item.type] = { ...item };
        return acc;
    }, {});
}

export { buildOptionsMap };