<template>
  <div class="wrapper-tax-purpose mx-auto d-flex flex-column justify-content-center align-items-center">
    <h4 class="tax-purpose-title">Your lot is in zoning district (R6, R7-1, R7-2, R8).</h4>
    <!-- <h5 class="tax-purpose-hint">Answers to these questions will help us make an accurate analysis.</h5> -->
    <div class="wrapper-radio-button">
      <b-form-group label="Is it on a wide street (75 feet +) or a narrow (under 74 feet)  street?" >
        <b-form-radio v-model="selected" size="lg" value="wide">Wide Street</b-form-radio>
        <b-form-radio v-model="selected" size="lg" value="narrow">Narrow Street</b-form-radio>
      </b-form-group>
    </div>
    <div class="sd-wrapper-button">
    <!-- <ButtonDefault @click="handleClickResult('condo')" text="Condo" /> -->
      <ButtonDefault @click="handleClickResult()" text="Next!" />
    </div>
    <SignUpCondoModal></SignUpCondoModal>
  </div>
</template>

<script>
import ButtonDefault from '@/components/ButtonDefault'
import SignUpCondoModal from '@/components/SignUpCondoModal.vue';
export default {
  name: "LotStreetTypeSelection",
  components:{
    ButtonDefault,
    SignUpCondoModal
  },
  data: () =>  ({
    selected: null
  }),
  methods:{
    handleClickResult(){
      this.$store.commit('setStreetTypeCustom', this.selected)
      this.$router.push({name :'build-date-selection'})
    },
  }
}
</script >

<style lang=scss scoped>
@import "../scss/variables.scss";

.wrapper-radio-button {
  display: flex;
  width: 500px;
  color: $font-black;


}
.wrapper-tax-purpose {
  height: 300px;
  width: 100%;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 8px 0 rgba(26,41,60,0.1);
  padding: 24px;
}
.tax-purpose-title {
  height: 33px;
  color: $font-black;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  width: 100%;
  margin-bottom: 36px;
}
.tax-purpose-hint{
  height: 19px;
  width: 100%;
  color: $font-gray;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 34px;
}
.sd-wrapper-button {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  margin-top: 24px;
}
.sd-wrapper-button > button {
  margin-top: 0;
  margin-right: 16px;
  width: 160px;
}
.sd-wrapper-button > button:last-of-type {
  margin-right: 0;
}

@media screen and (max-width: 768px) {
  .tax-purpose-title {
    height: 30px;
  }
  .wrapper-tax-purpose {
    height: 177px;
    width: 720px;
    padding: 20px;
  }
  .tax-purpose-hint{
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 420px) {
  .tax-purpose-title {
    font-size: 19px;
    letter-spacing: 0;
    line-height: 28px;
    height: 56px;
    width: 252px;
    margin-right: auto;
    margin-left: auto;
  }
  .wrapper-tax-purpose {
    height: 318px;
    width: 390px;
    padding: 16px;
    border-radius: 0;
  }
  .tax-purpose-hint{
    height: 34px;
    width: 223px;
    font-size: 12px;
    line-height: 17px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
  }
  .sd-wrapper-button {
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
  .btn-default{
    width: 358px;
    margin-bottom: 8px;
  }
}
@media screen and (max-width: 375px) {
  .wrapper-tax-purpose {
    height: 318px;
    width: 375px;
  }
  .btn-default{
    width: 343px;
  }
}
@media screen and (max-width: 320px) {
  .wrapper-tax-purpose {
    height: 318px;
    width: 320px;
  }
  .btn-default{
    width: 288px;
  }
}
</style>