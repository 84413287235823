<template>
  <div class="wrapper-form">
    <Preloader v-if="is_loading"></Preloader>
    <div v-if="address_not_found">
      <p class="nf-title">We couldn't find this property</p>
      <p class="nf-descr">Unfortunately, we couldn't find anything.
        Please check if the data is correct and try again.</p>
      <ButtonDefault @click="backToSearch" :need_forward="false" text="Try again!"/>
    </div>
    <div v-else>
      <InputAutocomplete id="searchAddress" placeholder="Search by the address" :empty_search="empty_search" :autocomplete="searchAddressAutocomplete" @inputTyping="inputTyping" @selectList="inputSelected" />
      <div class="wrapper-or-block">
        <span class="or-block">Or</span>
      </div>
      <CustomSelect class="borough-mob" id="borough" placeholder="Select borough" inputClass="borough" :autocomplete="boroughAutocomplete" @selectList="inputSelected" @handleFocus="handleFocus" />
      <div class="wrapper-address-details">
        <CustomSelect id="borough" placeholder="Select borough" inputClass="borough" class="borough-full" :autocomplete="boroughAutocomplete" @selectList="inputSelected" @handleFocus="handleFocus" />
        <InputDefault id="block" class="block-inp" placeholder="Block" @inputTyping="inputSelected" />
        <InputDefault id="lot" placeholder="Lot"  @inputTyping="inputSelected"/>
      </div>
      <ButtonDefault @click="getBBLBasicData" :disabled="isButtonDisabled" text="Go!"/>
    </div>
  </div>
</template>

<script>
import InputDefault from "@/components/InputDefault";
import InputAutocomplete from "@/components/InputAutocomplete";
import CustomSelect from "@/components/CustomSelect";
import ButtonDefault from "@/components/ButtonDefault";
import Preloader from "@/components/Preloader";
import { mapGetters } from "vuex";

export default {
  name: "SearchComponent",
  components: {
    InputDefault,
    InputAutocomplete,
    CustomSelect,
    ButtonDefault,
    Preloader,
  },
  props: {
    order: {
      type: String,
      required: false,
      default: ''
    },
  },
  data: () => ({
    boroughAutocomplete: ["Manhattan" , "Bronx", "Brooklyn", "Queens", "Staten Island"],
    borough_codes:[
      {name: "Bronx", value: 2},
      {name: "Manhattan", value: 1},
      {name: "Brooklyn", value: 3},
      {name: "Queens", value: 4},
      {name: "Staten Island", value: 5},
    ],
    form: {
      searchAddress: null,
      borough: null,
      block: null,
      lot: null,
    }
  }),
  computed: {
    ...mapGetters({
      bbl_data_status: 'get_BBLBasicData_status',
      search_data_status: 'get_BBLSearchResults_status',
      get_bblId: 'get_bblId',
    }),
    is_loading() {
      return this.bbl_data_status.is_loading;
    },
    isButtonDisabled() {
      return !this.form.searchAddress && (!this.form.borough || !this.form.block || !this.form.lot);
    },
    address_not_found() {
      return !!this.bbl_data_status.error;
    },
    empty_search() {
      if (this.search_data_status.is_loading) return false;
      return this.search_data_status.data && this.search_data_status.data.length === 0;
    },
    searchAddressAutocomplete() {
      return this.search_data_status.data || [];
    }
  },
  watch: {
  },
  methods: {
    async inputTyping({inputId, inputValue}) {
      this.inputSelected({ inputId, inputValue });

      if (inputValue.length > 3 && inputId === 'searchAddress') {
        this.$store.dispatch('getBBLSearchResults', { inputValue });
      } else {
        this.$store.commit('setBBLSearchResults_status', { data: null, is_loading: false, error: null });
      }
    },
    backToSearch() {
      this.$store.commit('resetState');
      this.form = {
        searchAddress: null,
        borough: null,
        block: null,
        lot: null,
      }
    },
    getBBLBasicData() {
      this.$store.dispatch('getBBLBasicData', {
        block: this.form.block,
        boroughCode: this.form.borough ? this.borough_codes.find(item=>item.name === this.form.borough).value : '',
        lot: this.form.lot,
        searchAddress: this.form.searchAddress
      })
    },
    uuid() {
      return 'txxxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
      });
    },
    inputSelected({inputId, inputValue}) {
      this.form[`${inputId}`] = inputValue;
    },
    handleFocus({inputId}) {
      this[`${inputId}Autocomplete`] = ["Manhattan","Bronx","Brooklyn","Queens", "Staten Island"];
    }
  }
}
</script>

<style lang="scss">
@import "../scss/variables.scss";
.borough-mob{
  display: none;
}
.or-block {
  display: block;
  height: 60px;
  width: 100%;
  color: $font-gray;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 60px;
  text-align: center;
  position: relative;
  margin: 0 auto;
}
.or-block:before,
.or-block:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 5;
  height: 1px;
  width: 246px;
  background-color: $white-color;
  top: 30px;
}
.or-block:before {
  left: 0;
}
.or-block:after {
  right: 0;
}
.wrapper-form {
  height: 272px;
  width: 656px;
  border-radius: 8px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 6px 0 rgba(26,41,60,0.15);
  padding: 24px;
  position: relative;
}
@media screen and (max-width: 768px) {
  wrap-header {
    max-width: 1320px;
    height: 63px;
    margin: 0 auto;
    padding: 24px 24px;
    border-bottom: 1px solid  #E6E8FF;
    overflow: hidden;
  }
}
@media screen and (max-width: 420px) {
  .wrapper-form {
    height: 296px;
    width: 390px;
    border-radius:0;
    padding: 16px;
  }
  .or-block {
    height: 44px;
    width: 100%;
    color: $font-gray;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 44px;
    position: relative;
    margin: 0
  }
  .or-block:before,
  .or-block:after {
    height: 1px;
    width: 155px;
    top: 22px;
  }
  .borough-mob{
    display: block;
  }
  .borough-full{
    display: none;
  }
  .custom-select_input__wrapper{
    margin-bottom: 16px;
  }
  .block-inp{
    margin-left: 0!important;
  }
}
@media screen and (max-width: 375px) {
  .wrapper-form {
    height: 296px;
    width: 375px;
    border-radius:0;
    padding: 16px;
  }
  .block-inp{
    margin-left: 0!important;
  }
}
@media screen and (max-width: 325px) {
  .wrapper-form {
    height: 296px;
    width: 320px;
    border-radius:0;
    padding: 16px;
  }
  .or-block:before,
  .or-block:after {
    height: 1px;
    width: 120px;
    top: 22px;
  }
  .block-inp{
    margin-left: 0!important;
  }
}
</style>