<template>
  <div>
    <Navbar></Navbar>
    <StepsContainer>
      <router-view/>
    </StepsContainer>
  </div>
</template>

<script>
import Navbar  from '@/components/NavBar.vue';
import StepsContainer from '@/components/StepsContainer';
// import axios from "axios";

export default {
name: "StepWrap",
  components: {
    Navbar,
    StepsContainer
  },
  created(){
    // if (!this.bblId ) {
    //   this.$store.commit('setBBLId', this.$route.params.id)
    //   this.$store.dispatch('getBBLBasicData', {
    //     bblId: this.$route.params.id
    //   })
    //   this.$store.dispatch('getBBLData')
    // }
  }
}

//  async getCoords(){
//    let url = `https://old.portal.mgnyconsulting.com:8091/API/PublicPortal/getBBLEGeoData/${this.$route.params.id}`
//    const { data } = await axios.get(this.cors_url + url);
//    this.$store.commit('setGoogleCords', {latitude: data['Latitude'], longitude: data['Longitude']})
//  },

</script>

<style scoped>

</style>