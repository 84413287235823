<template>
  <div class="wrapper-footer">
    <div class="footer">
      <div class="hr d-flex">
        <img class="main-logo-img mr-auto" src="../assets/logo_regtime.svg" alt="">
        <!-- <a class="anchor-link" href="#" @click.prevent="scrollTo('how_it_work')">How it works</a>
      <a class="anchor-link" href="#"  @click.prevent="scrollTo('to_find_out')">Contact us</a> -->
      </div>
    </div>
    <p class="copyright">Copyright © 2025 Regtime, Inc. All rights reserved.</p>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    scrollTo(query){
      const el = document.getElementById(query);
      if (el) {
        el.scrollIntoView({behavior: 'smooth'});
      }
    }
  }
}
</script>

<style scoped>
.wrapper-footer {
  background-color: #F9FAFF;
}
.footer {

  width: 100%;
  margin: 0 auto;
  padding: 0 60px 24px;
  overflow: hidden;
}
.hr{
  padding-top: 24px;
  border-top: 1px solid #E6E8FF;
}
.main-logo-img {
  height: 24px;
  width: 112px;
  margin: 0;
}
.anchor-link {
  color: #0A1250;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  margin-left: 32px;
}
.anchor-link:hover {
  text-decoration: none;
}
.copyright {
  margin-top: 86px;
  text-align: center;
  color: #546C92;
  font-family: "Open Sans";
  font-size: 12px;
  letter-spacing: 0;
  line-height: 19px;
  margin-bottom: 0px;
  padding: 11px 0 10px;
  background: #F4F6FF;
}
@media screen and (max-width: 768px) {
  .footer {
    border-top: 1px solid #E6E8FF;
    width: 100%;
    margin: 0 auto;
    padding: 24px ;
    overflow: hidden;
  }
}
@media screen and (max-width: 420px) {
  .footer {
    padding:0;
    background-color: #F4F6FF;
  }
  .main-logo-img{
    display: none;
  }
  .anchor-link{
    display: none;
  }
  .copyright {
    margin-top: 0;
    height: 40px;
    line-height: 40px;

  }
}
</style>
