var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"title-rep"},[_vm._v("REQUEST FULL 485x/UAP REPORT")]),_c('h5',{staticClass:"description"},[_vm._v("Our advisory report is packed with actionable data and insights.")]),_c('h5',{staticClass:"description price"},[_vm._v("The full report costs $750 and contains the following:")]),_c('div',{staticClass:"full-rep-wrap"},[_c('div',{staticClass:"cards-wrap"},[_c('div',{staticClass:"custom-card card-blue"},[_vm._m(0),_c('div',{staticClass:"custom-card-body"},_vm._l((_vm.report_recommendations),function(item,index){return _c('div',{key:index,staticClass:"body-item-wrap d-flex justify-content-between"},[_c('div',{staticClass:"body-item"},[_vm._v(" "+_vm._s(item)+" ")]),_vm._m(1,true)])}),0)]),_c('div',{},[_c('div',{staticClass:"custom-card card-green"},[_vm._m(2),_c('div',{staticClass:"custom-card-body"},_vm._l((_vm.report_insights),function(item,index){return _c('div',{key:index,staticClass:"body-item-wrap d-flex justify-content-between"},[_c('div',{staticClass:"body-item"},[_vm._v(" "+_vm._s(item)+" ")]),_vm._m(3,true)])}),0)])]),_c('div',{},[_c('div',{staticClass:"custom-card card-orange"},[_vm._m(4),_c('div',{staticClass:"custom-card-body"},_vm._l((_vm.report_actions),function(item,index){return _c('div',{key:index,staticClass:"body-item-wrap d-flex justify-content-between"},[_c('div',{staticClass:"body-item"},[_vm._v(" "+_vm._s(item)+" ")]),_vm._m(5,true)])}),0)])])])]),_c('div',{staticClass:"d-flex justify-content-center buttons"},[_c('ButtonDefault',{directives:[{name:"b-modal",rawName:"v-b-modal.sign-up-modal",modifiers:{"sign-up-modal":true}}],attrs:{"need_forward":false,"btn-class":"btn-clss","text":"Request Full Report"}}),_c('ButtonLight',{directives:[{name:"b-modal",rawName:"v-b-modal.not-request",modifiers:{"not-request":true}}],attrs:{"need_forward":false,"btn-class":"btn-clss","text":"Thanks, I will pass"}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-card-header light-blue d-flex justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"title-card"},[_vm._v("Recommendations")])]),_c('div',[_c('img',{staticClass:"card-header-img",attrs:{"src":require("../../assets/fin_ill_1.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"card-body-img",attrs:{"src":require("../../assets/arrow_1_Color.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-card-header light-green d-flex justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"title-card"},[_vm._v("Deep Insights")])]),_c('div',[_c('img',{staticClass:"card-header-img",attrs:{"src":require("../../assets/fin_ill_2.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"card-body-img",attrs:{"src":require("../../assets/arrow_2_color.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-card-header light-orange d-flex justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"title-card"},[_vm._v("Action Plan")])]),_c('div',[_c('img',{staticClass:"card-header-img",attrs:{"src":require("../../assets/fin_ill_3.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{staticClass:"card-body-img",attrs:{"src":require("../../assets/arrow_3_color.svg"),"alt":""}})])
}]

export { render, staticRenderFns }