<template>
  <div>
    <h3 class="title-rep">REQUEST FULL 485x/UAP REPORT</h3>
    <h5 class="description">Our advisory report is packed with actionable data and insights.</h5>
    <h5 class="description price">The full report costs $750 and contains the following:</h5>
    <div class="full-rep-wrap">
      <div class="cards-wrap">
        <div class="custom-card card-blue">
          <div class="custom-card-header light-blue d-flex justify-content-between">
            <div class="d-flex align-items-center">
              <div class="title-card">Recommendations</div>
            </div>
            <div>
              <img src="../../assets/fin_ill_1.png" alt="" class="card-header-img">
            </div>
          </div>
          <div class="custom-card-body">
            <div v-for="(item, index) in report_recommendations" :key="index"
              class="body-item-wrap d-flex justify-content-between">
              <div class="body-item">
                {{item}}
              </div>
              <div>
                <img src="../../assets/arrow_1_Color.svg" alt="" class="card-body-img">
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="custom-card card-green">
            <div class="custom-card-header light-green d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <div class="title-card">Deep Insights</div>
              </div>
              <div>
                <img src="../../assets/fin_ill_2.png" alt="" class="card-header-img">
              </div>
            </div>
            <div class="custom-card-body">
              <div v-for="(item, index) in report_insights" :key="index"
                class="body-item-wrap d-flex justify-content-between">
                <div class="body-item">
                  {{ item }}
                </div>
                <div>
                  <img src="../../assets/arrow_2_color.svg" alt="" class="card-body-img">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="custom-card card-orange">
            <div class="custom-card-header light-orange d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <div class="title-card">Action Plan</div>
              </div>
              <div>
                <img src="../../assets/fin_ill_3.png" alt="" class="card-header-img">
              </div>
            </div>
            <div class="custom-card-body">
              <div v-for="(item, index) in report_actions" :key="index"
                class="body-item-wrap d-flex justify-content-between">
                <div class="body-item">
                  {{item}}
                </div>
                <div>
                  <img src="../../assets/arrow_3_color.svg" alt="" class="card-body-img">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center buttons">
      <ButtonDefault :need_forward="false" btn-class="btn-clss" v-b-modal.sign-up-modal text="Request Full Report">
      </ButtonDefault>
      <ButtonLight :need_forward="false" btn-class="btn-clss" v-b-modal.not-request text="Thanks, I will pass">
      </ButtonLight>
    </div>
  </div>
</template>

<script>
import ButtonDefault from '../../components/ButtonDefault.vue';
import ButtonLight from '../../components/ButtonLight';

export default {
  name: "FullReport",
  components:{
    ButtonDefault,
    ButtonLight,
  },
  data: () => ({
    report_recommendations: [
      'Multiple Scenario Analysis',
      'Avoid Construction Wage Requirement',
      '485x and ICAP match-up',
      'Max Affordable Rent and Subsidies',
    ],
    report_insights: [
      'Ideal Affordability Levels and Unit Mix',
      'Real Estate Tax Projections',
      'Revenue Analysis',
      'Costs and Fees Analysis',
    ],
    report_actions:[
      '485x Application Roadmap',
      'City of Yes UAP Application Roadmap',
      'Affordable Housing Marketing Plan',
      'Affordable Housing Compliance Guidelines',
    ]
  }),
}
</script>

<style lang="scss" scoped>
@import "../../scss/variables.scss";
.full-rep-wrap{
}
.cards-wrap{
  display: flex;
  justify-content: space-between;
}
.btn-clss{
  width: 240px;
  margin: 24px 6px 0;

}
.card-header-img{
  height: 40px;
  width: 40px;
}
.body-item{
  width: 175px;
}
.body-item-wrap{
  margin-bottom: 16px;

}
.body-item{

  color: #0A1250;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 24px;

}
.title-card{
  height: 19px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
}
.custom-card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 16px 12px;
}
.custom-card{
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  box-sizing: border-box;
  height: 356px;
  width: 259px;
  border: 1px solid #E6E8FF;
  border-radius: 10px;
  background-color: #FFFFFF;
}
.custom-card-header {
  padding: 12px;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  box-sizing: border-box;
  height: 64px;
  border-radius: 10px 10px 0 0;
}
.light-blue{
  background-color: #F9FAFF;
}
.light-green{
  background-color: #F2FAE6;
}
.light-orange{
   background-color: #FFF8E5;
}
.card-blue{
  border-color: #E6E8FF !important;
}
.card-green{
  border-color: rgba(167,220,89,0.4) !important;
}
.card-orange{
  border-color: #FFEFC2 !important;
}
.text-item{
height: 19px;
color: $font-black;
font-family: "Open Sans";
font-size: 14px;
letter-spacing: 0;
line-height: 19px;
margin-bottom: 4px;
}
.sign{
height: 24px;
width: 24px;
/*background-color: #FFFFFF;*/
  box-shadow: 0 2px 4px 0 rgba(26,41,60,0.15);
  border-radius: 50%;
  background: url('../../assets/icn_arrow_rounded_Color.svg') no-repeat center ;
}
.check-icon{
  height: 24px;
  width: 24px;
}
.report-item{
  height: 48px;
  width: 320px;
  border-radius: 6px;
  background-color: $common-bg;
  /*background-color: #014747;*/
  padding: 12px;
  margin-bottom: 16px;
}
.title-rep{
  width: 100%;
  font-size: 32px;
  letter-spacing: -0.56px;
  line-height: 43px;
  color: $font-black;
  font-family: "Open Sans";
  text-align: center;
  margin-bottom: 12px;
}
.description{
  height: 22px;
  width: 100%;
  color: $font-black;
  font-family: "Open Sans";
  margin-bottom: 0;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 22px;
  text-align: center;
}
.price{
  font-weight: 600;
  margin-bottom: 32px;
}
.report-item{

}
@media screen and (max-width: 1025px) {
  .title-rep{
    margin-top: -48px;
  }

}
@media screen and (max-width: 768px) {
  .title-rep{
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 20px;
    letter-spacing: -0.35px;
    line-height: 27px;
    margin-bottom: 4px;
    font-weight: 400;
    margin-top: -48px;
  }
  .custom-card{
    width: 218px;
  }

}
@media screen and (max-width: 420px) {
  .title-rep{
    margin-top: 0px;
  }
  .cards-wrap{
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .description{
      margin-bottom: 24px;
    }
  .body-item{
    width: auto;
    height: 24px!important;
  }
  .custom-card{
    width: 100%;
    margin-bottom: 16px;
    height: auto;
  }
  .buttons{
    flex-direction: column;
    margin-bottom: 24px;
  }
  .btn-clss{
    margin: 0 0 8px 0;
    width: 100%;
  }
  .custom-card-body{
    padding: 16px 16px 0px 16px!important;
  }
}
@media screen and (max-width: 375px) {
  .title-rep{
    margin-top:0;
    font-size: 16px;
    line-height: 24px;
  }
  .description{
    margin-bottom: -4px;
  }
  .price{
    margin-bottom: 16px;
  }
  .buttons{
    flex-direction: column;
  }
  .btn-clss{
    margin: 0 0 8px 0;
    width: 100%;
  }
  .description{
    font-size: 11px;
    line-height: 15px;
  }
  .custom-card{
    width: 343px;
  }
}
@media screen and (max-width: 320px) {
  .custom-card {
    width: 288px;
  }
  .custom-card-body {
    padding: 14px 12px 0px 12px !important;
  }
  .description{
    height: auto;
    margin-bottom: 0;
  }
  .price{
    margin-bottom: 16px;
  }
}

</style>