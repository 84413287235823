<template>
    <div class="rectangle d-flex justify-content-between">
        <div @click="stepBack()" class="align-self-center d-flex align-items-center justify-content-center hand round-block">
            <img class="back-img" src="../assets/icn_back.svg" alt="">
            <div class="back">Back</div>
        </div>
        <div class="align-self-center">
          <div class="">
            <b-progress :value="step_number" :max="total_steps"  variant="custom-progress" class="custom-progress"></b-progress>
          </div>
        </div>
        <div class="align-self-center d-flex align-items-center justify-content-center round-block">
            <div class="step">
              <span class="step-number">{{step_number}}</span> <span>of</span> <span>{{total_steps}}</span>
            </div>
        </div>
      </div>
</template>

<script>
export default {
name: "ProgressBar",
  data: () => ({
    total_steps: 5,
    step_number : null
  }),
  computed: {
    stepRoutes() {
      return this.$router.getRoutes().filter(route => route.meta.step).sort((a, b) => a.meta.step - b.meta.step)
    }
  },
  methods:{
    stepBack() {
      const step = this.stepRoutes
        .filter(route => route.meta.filter ? route.meta.filter(this.$store.getters) : true)

      const currentStep = step.find(route => route.name === this.$route.name)  
      const previousStep = step[step.indexOf(currentStep) - 1]
      // console.log('stepBack', this.stepRoutes.filter(route => route.meta.filter ? route.meta.filter(this.$store.getters) : true), previousStep)

      this.$router.push({ name: previousStep?.name || 'home' })
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  },

  watch: {
    '$route.meta.step': {
      handler: function () {
        if(this.$route.meta.step !== this.step_number){
          console.log('step_number', this.$route.meta.step)
          this.step_number = this.$route.meta.step
          this.$store.dispatch('sendInfo', { step: this.step_number })
        }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang="scss" >
@import "../scss/variables.scss";
.step-number{
  height: 19px;
  width: 37px;
  color: $font-blue;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
  font-weight: 600;
}
.bg-custom-progress{
  background-color: $font-blue !important;
  border-radius: 10px;
}
</style>
<style lang="scss" scoped>
@import "../scss/variables.scss";
.custom-progress{
  height: 6px!important;
  width: 234px;
  border-radius: 10px;
  background-color: $white-color!important;
}
.rectangle {
  height: 40px;
  width: 424px;
  border-radius: 20px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 rgba(26,41,60,0.15);
}
.hand{
  cursor: pointer;
}
.round-block{

  margin: 0 3px;
  height: 34px;
  width: 80px;
  border-radius: 20px;
  background-color: $common-bg;
}
.back-img {
  height: 10px;
  width: 16px;
  color: #96A8BE;
  margin-right: 8px;
}
.back {
  height: 19px;
  width: 31px;
  color: $font-gray;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}
.step{
  height: 19px;
  width: 37px;
  color: $font-gray;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
}
@media screen and (max-width: 768px) {
  .rectangle {
    width: 360px;
    border-radius: 20px;
    background-color: #FFFFFF;
    box-shadow: 0 2px 4px 0 rgba(26,41,60,0.15);
  }
  .custom-progress{
    width: 170px;
  }
}
@media screen and (max-width: 420px) {
  .rectangle {
    box-shadow: unset;
    border-radius: 0 !important;
    width: 100%;
    padding: 0 16px 20px 16px;
  }
}
</style>