<template>
  <b-modal id="sign-up-condo-modal" body-bg-variant="coool" header-bg-variant="coool" header-class="header-class"
    @hidden="resetModal()" hide-footer size="lg" dialog-class="modal-dialog-bottom" body-class="modal-class">
    <div class="close-btn" @click="$bvModal.hide('sign-up-condo-modal')">
      <img src="../assets/icn_close_modal.svg" class="cross" alt="X">
    </div>
    <Preloader v-if="is_loading"></Preloader>
    <div v-if="sent_success" class="sent-pad">
      <div class="d-block text-center title-sent">
        <p class="title-main">Thank you!</p>
        <p class="descr">We will be in touch with you shortly. </p>
      </div>
      <button @click="$bvModal.hide('sign-up-condo-modal')" class="help-btn ">Done!</button>
    </div>
    <div v-else-if="sent_error" class="sent-pad">
      <!--      <img src="../assets/error_outline.png" class="d-block mx-auto" alt="">-->
      <div class="d-block text-center">
        <p class="title-main">Something went wrong</p>
        <div class="descr form-sent">
          <p class="mb-0">We are working on this problem now. </p>
          <p class="mb-0">Please try again or contact us: </p>
          <p class="mb-0"><b>718-522-1111</b></p>
        </div>
        <button @click="$bvModal.hide('sign-up-condo-modal')" class="help-btn  ">Request a call</button>
      </div>
    </div>
    <div v-else class="form-wrap">
      <div class="d-flex justify-content-center">
        <div>
          <div class="title-main text-center">
            <div class="title-main-txt">
              Thank you for utilizing our UAP/485x calculator.{{ '\n'
              }}Condo projects require additional analysis.
            </div>
          </div>
          <p class="title-descr text-center">
            Provide your contact information, and we’ll reach out to with more information.
          </p>
        </div>
      </div>
      <div class="wrap-inputs">
        <div class="row radios-block">
          <div class="col-md-6 col-12 mb-32px">
            <b-form-input id="name" class="cust-inp data-hj-allow" :class="name_state === false ? 'err-state' : ''"
              v-model="name" :state="name_state" placeholder="First name" trim type="text"></b-form-input>
            <div v-if="name_state === false">
              <p v-if="!name || name.length === 0" class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                First name is incorrect
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-32px">
            <b-form-input id="lname" :class="last_name_state === false ? 'err-state' : ''"
              class="cust-inp data-hj-allow" v-model="last_name" :state="last_name_state" placeholder="Last name" trim
              type="text"></b-form-input>
            <div v-if="last_name_state === false">
              <p v-if="!last_name || last_name.length === 0" class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                Last name is incorrect
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-32px">
            <b-form-input id="mail" :class="mail_state === false ? 'err-state' : ''" class="cust-inp data-hj-allow"
              v-model="mail" :state="mail_state" placeholder="Email" type="email" trim></b-form-input>
            <div v-if="mail_state === false">
              <p v-if="!mail || mail.length === 0" class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                Email is incorrect
              </p>
            </div>
          </div>
          <div class="col-md-6 col-12 mb-32px">
            <TheMask class="cust-inp form-control data-hj-allow" v-model="phone" type="tel"
              :class="phone_state === false ? 'err-state' : ''" placeholder="Phone Number" mask="(###) ###-####">
            </TheMask>
            <div v-if="phone_state === false">
              <p v-if="!phone || phone.length === 0" class="err-state-text m-0">
                Required
              </p>
              <p v-else class="err-state-text m-0">
                Phone number is incorrect
              </p>
            </div>
          </div>
          <div class="col-12">
            <div v-if="sent_success || sent_error" class="lic-less d-flex justify-content-center hand">
              <button @click="$bvModal.hide('sign-up-condo-modal')" class="help-btn  w-100">Close</button>
            </div>
            <div v-else class="lic-less d-flex justify-content-center">
              <HelpButton @click.native="signUp" text="Request a call" class="req-btn"
                :class="disabled_send ? 'not-allowed' : 'hand'"></HelpButton>

            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import HelpButton from '../components/HelpButton'
import Preloader from '../components/Preloader.vue'
import sbjs from 'sourcebuster';
import Vue from "vue";
import { mapGetters } from "vuex";

Vue.use(sbjs)
sbjs.init();
export default {
  name: "SignUpCondoModal",
  components: {
    TheMask,
    Preloader,
    HelpButton
  },

  data: () => ({
    name_state: null,
    last_name_state: null,
    mail_state: null,
    phone_state: null,
    income_state: null,
    name: null,
    last_name: null,
    mail: null,
    phone: null,
    sent_success: false,
    sent_error: false,
    is_loading: false,
    disabled_send: false,
    ConstructionCost: null,
    ConstructionType: null,
    BBL: null,
    result: null,
    noi_level: null
  }),
  computed: {
    ...mapGetters({
      get_all: 'get_all',
      zoning: 'get_zoning',
      neighborhoodName: 'get_neighborhoodName',
      buildingClassDescription: 'get_buildingClassDescription',
      bblId: 'get_bblId',
      currentFiscalYear: 'get_currentFiscalYear',
      income: 'get_income',
      income_client: 'get_income_client',
      expenses: 'get_expenses',
      expenses_client: 'get_expenses_client',
      area_client: 'get_area_client',
      area: 'get_area',
      relationshipToProperty: 'get_relationshipToProperty',
      vacanciesInTheLastTwentyMonths: 'get_vacanciesInTheLastTwentyMonths',
      vacant_area: 'get_vacant_area',
      ownerOccupancyInTheLastTwentyMonths: 'get_ownerOccupancyInTheLastTwentyMonths',
      rented_area_sf: 'get_rented_area_sf',
      owner_area_sf: 'get_owner_area_sf',
      scoreResults: 'scoreResults',
      guid: 'get_guid',
      address: 'get_address',
      block: 'get_block',
      lot: 'get_lot',
      boroughCode: 'get_boroughCode',
    })
  },
  watch: {
    name() {
      this.name_state = null
    },
    last_name() {
      this.last_name_state = null
    },
    mail() {
      this.mail_state = null
    },
    phone() {
      this.phone_state = null
    },
  },
  methods: {
    startModal(paramz) {
      this.ConstructionCost = paramz.ConstructionCost;
      this.ConstructionType = paramz.ConstructionType;
      this.BBL = paramz.BBL;
      this.result = paramz.result;

      this.$bvModal.show('sign-up-condo-modal')
    },
    resetModal() {
      this.sent_success = false;
      this.is_loading = false;
      this.sent_error = false;
      this.name = null;
      this.last_name = null;
      this.mail = null;
      this.phone = null;
      this.name_state = null;
      this.last_name_state = null;
      this.mail_state = null;
      this.phone_state = null;

      this.BBL = null;
      this.result = null;

    },
    goBack() {
      this.$bvModal.hide('sign-up-condo-modal')

    },

    signUp() {
      if (this.validate() && !this.disabled_send) {
        this.makeDisabled()

        this.$store.dispatch('sendInfo', {
          FirstName: this.name,
          LastName: this.last_name,
          Email: this.mail,
          Phone: this.phone,
        })
      }

    },
    makeDisabled() {
      this.disabled_send = true;
      setTimeout(() => this.disabled_send = false, 10000)
    },
    validate() {
      this.name_state = null;
      this.last_name_state = null;
      this.mail_state = null;
      this.phone_state = null;
      let is_valid = true
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (this.name === null || this.name.length < 2) {
        this.name_state = false;
        is_valid = false
      }
      if (this.last_name === null || this.last_name.length < 2) {
        this.last_name_state = false;
        is_valid = false
      }
      if (this.mail === null || this.mail.length < 5 || !(re.test(String(this.mail).toLowerCase()))) {
        this.mail_state = false;
        is_valid = false
      }
      // if(this.phone && this.phone.length !== 10){
      if (this.phone === null || this.phone.length !== 10) {
        this.phone_state = false;
        is_valid = false
      }

      return is_valid

    }
  }
}
</script>
<style>
.req-btn {
  width: 360px !important;
}

.wrap-inputs {
  width: 100% !important;
  margin-top: 32px;
}

.not-allowed {
  cursor: not-allowed;
}

.modal-header {
  border-bottom: none !important;
}

.bg-coool {
  background: #FFFFFF;
}

.modal-content {
  border-radius: 10px 10px 10px 10px !important;
}

.modal-class {
  border-radius: 10px !important;
}

.header-class {
  border-radius: 10px !important;
}

.modal-footer>* {
  margin: 0 !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  color: #FF2929;
}

.thank_img {
  height: 79.51px;
  width: 103.6px;
  margin-bottom: 52px;
}

.form-control {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
}

.modal-backdrop {
  transition: 0.8s;
  opacity: 0.88 !important;
  background-color: rgba(1, 2, 15, 1) !important
}

#sign-up-condo-modal___BV_modal_header_ {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 1223px) {
  .close-btn {
    display: none;
  }

  #sign-up-condo-modal___BV_modal_header_ {
    display: block;
  }

  .modal-dialog {
    max-width: 640px !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .close-btn {
    display: none;
  }

  #sign-up-condo-modal___BV_modal_header_ {
    display: none;
  }
}
</style>


<style lang="scss" scoped>
.help-btn {
  width: 240px;
}

.title-main-txt {
  white-space: pre-line;
}

.title-sent {
  margin-bottom: 0;
}

.req-btn {
  width: 360px !important;
}

.wrap-inputs {
  width: 100% !important;
  margin-top: 32px;
}

.title-descr {
  width: 464px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  margin: 0 auto 0;
}

.back-container {
  padding: 20px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-btn {
  height: 20px;
  width: 35px;
  color: #4D59EB;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;

}

.close-btn {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #4b4d56;
  box-shadow: 0 2px 4px 0 rgba(26, 41, 60, 0.15);
  position: absolute;
  top: 0;
  right: -300px;
  cursor: pointer;
  z-index: 999;
  padding: 8px 13px;
}

.cross {
  height: 15px;
  width: 15px;
  color: #FFFFFF;
}

.sent-pad {
  padding: 0;
  text-align: center;
}

.err-state-text {
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
}

.err-state {
  background-color: #FFE9E8 !important;
  border: 1px solid #FF7564 !important;

  &::placeholder {
    color: #FF7564 !important;
    font-family: "Open Sans";
  }
}

.err-state.long {
  width: 350px;
  right: 0px;
}

.err-state::after {
  content: '';
  position: absolute;
  left: 49%;
  top: 100%;
  border-left: 4px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f60606;
}

::placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  color: #C6C6C6;
}

.mb-32px {
  margin-bottom: 32px;
}

.mb-40px {
  margin-bottom: 40px;
}

.thank {
  width: 109.6px;
  margin-bottom: 50px;
}

.form-wrap {
  padding: 0;
}

.title-main {
  width: 722px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 28px;
  letter-spacing: -0.63px;
  line-height: 49px;
  text-align: center;
  margin: 0 auto 12px;
}

.title-bad {
  width: 536px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  margin: 0 auto 12px;
}

.help-text-bad {
  width: 321px;
  color: #7F83A7;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin: auto;
}

.help-text {
  width: 416px;
  color: #7F83A7;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin: auto;
}

.descr {
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-bottom: 32px;
}

.text-blue {
  color: #2692F5;
}

.description-wrap {
  padding: 30px
}

.cust-inp {
  height: 48px;
  border-radius: 6px;
  border-color: transparent;
  background-color: #F9FAFF;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  color: #0A1250;

  &::placeholder {
    color: #7F83A7;
    font-family: "Open Sans";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
  }

  &:active {
    box-sizing: border-box;
    height: 48px;
    border: 1px solid #4D59EB;
    border-radius: 6px;
  }

  &:focus {
    box-shadow: none;
    border: 1px solid #4D59EB;
  }
}

.hand {
  cursor: pointer;
}

@media screen and (max-width: 768px) and (max-width: 1223px) {
  .form-wrap {
    margin-top: -48px;
  }

  .req-btn {
    width: 360px !important;
  }

  .title-descr {
    width: 464px;
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    margin: 0 auto 0;
  }

  .wrap-inputs {
    padding: 0;
    width: 100% !important;
    margin-top: 32px;
  }

  .title-main {
    margin: 0 0 12px;
  }

  .title-bad {
    margin: -29px 0 12px;
  }
}

@media screen and (min-width: 415px) and (max-width: 767px) {
  .form-wrap {
    padding: 0;

  }

  .title-main {
    width: auto;
    margin: -30px 0 12px;
    font-size: 24px;
    line-height: 33px;
  }

  .title-main-second {
    white-space: pre-line;
  }

  .wrap-inputs {
    padding: 0;
    width: 100% !important;
    margin-top: 25px;
  }

  .mb-32px {
    margin-bottom: 28px;
  }

  .sent-pad {
    padding: 0 0px 38px;
  }

  .descr {
    margin-bottom: 60px;
    font-size: 24px;
    line-height: 33px;
  }

  .help-text {
    width: 288px
  }

  .title-bad {
    width: 338px
  }
}

@media screen and (max-width: 415px) {
  .form-wrap {
    padding: 0;
    margin-top: 16px;
  }

  .title-main-txt {
    display: block;
  }

  .title-main-second {
    display: none;
  }

  .title-descr {
    width: 309px;
    font-size: 12px;
    line-height: 17px;
    margin: 0 auto 0;
  }

  .title-main {
    width: 323px;
    margin: -0 auto 12px;
    font-size: 19px;
    line-height: 28px;
  }

  .wrap-inputs {
    padding: 0;
    width: 100% !important;
    margin-top: 25px;
  }

  .mb-32px {
    margin-bottom: 16px;
  }

  .sent-pad {
    padding: 0 0px 18px;
  }

  .descr {
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 22px;
  }

  .help-text {
    width: 288px
  }

  .title-bad {
    width: 338px
  }

  .title-sent {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 375px) {
  .form-wrap {
    padding: 0;
    margin-top: 16px;
  }

  .wrap-inputs {
    padding: 0;
    width: 100% !important;
    margin-top: 25px;
  }

  .mb-32px {
    margin-bottom: 16px;
  }

  .sent-pad {
    padding: 0 0px 18px;
  }

  .help-text {
    width: 288px
  }

  .title-bad {
    width: 338px
  }

  .title-main {
    width: 330px;
    margin: 0 0 12px;
    font-size: 19px;
    line-height: 26px;
  }

  .title-sent {
    margin-bottom: 40px;
  }

  .descr {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 33px;
  }
}

@media screen and (max-width: 320px) {
  .title-main {
    width: 302px;
    margin: 0 0 12px;
    font-size: 18px;
    line-height: 24px;
  }

  .err-state-text {
    font-size: 10px;
    top: 45px
  }

  .form-wrap {
    padding: 0;
    margin-top: 16px;
  }

  .help-text {
    width: 288px
  }

  .title-bad {
    width: 295px
  }

  .help-text-bad {
    width: 288px
  }

  .wrap-inputs {
    padding: 0 1px 15px;
    width: 100% !important;
    margin-top: 15px;
  }

  .mb-32px {
    margin-bottom: 12px;
  }

  .sent-pad {
    padding: 0;
  }

  .descr {
    margin-bottom: 50px;
    width: 230px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }
}
</style>