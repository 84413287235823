import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import HomeRegtime from '../views/regtime/HomeRegtime.vue'
import StepWrap from '../views/StepWrap.vue'
// import TaxPurpose from '../views/TaxPurpose.vue'
// import TaxPurposeSettings from '../views/TaxPurposeSettings.vue'
// import RelationshipToProperty from '../views/RelationshipToProperty.vue'
// import VacanciesLast12Month from '../views/VacanciesLast12Month.vue'
// import PercentageVacantProperty from '../views/PercentageVacantProperty.vue'
// import OwnerVsBreakdown from '../views/OwnerVsBreakdown.vue'
// import OwnerOccupancy from '../views/OwnerOccupancy.vue'

import LotAreaConfirmation from  '../views/LotAreaConfirmation.vue'
import LotTypeSelection from  '../views/LotTypeSelection.vue'
import BuildDateSelection from  '../views/BuildDateSelection.vue'
import Results from '../views/Results.vue'
import LotStreetTypeSelection from '../views/LotStreetTypeSelection.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/item/:id',
    name: 'step',
    redirect: { name: 'lot-area-confirmation' },
    component: StepWrap,
    children: [
      {
        path: 'lot-area-confirmation',
        name: 'lot-area-confirmation',
        component: LotAreaConfirmation,
        meta: { step: 1 }
      },
      {
        path: 'lot-type-selection',
        name: 'lot-type-selection',
        component: LotTypeSelection,
        meta: { step: 2 }
      },
      {
        path: 'lot-street-type-selection',
        name: 'lot-street-type-selection',
        component: LotStreetTypeSelection,
        meta: {
          step: 3, filter: (getters) => {
            console.log('getters.get_need_streetType_custom', getters.get_need_streetType_custom)
            return getters.get_need_streetType_custom
          }
        }
      },
      {
        path: 'build-date-selection',
        name: 'build-date-selection',
        component: BuildDateSelection,
        meta: { step: 4 }
      },      
      {
        path: 'results',
        name: 'results',
        component: Results,
        meta: { step: 5 }
      },
    ]
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const state = JSON.parse(localStorage.getItem('store'));
  if (to.name !== 'home' && (!state || !state.bblId)) {
    console.log('redirecting to home');
    next({ name: 'home' });
  } else {
    next();
  }
});

export default router
