<template>
  <div class="d-flex justify-content-center align-items-center" style="height: 100vh;">
    <b-spinner label="Loading..."></b-spinner>
  </div>
</template>

<script>
export default {
name: "Preloader"
}
</script>

<style lang="scss" scoped>
@import "../scss/variables.scss";
// .preload-img{
//   background: url(../assets/preload.gif) -128px -111px;
//   background-size: 365px;
//   width: 125px;
//   height: 125px;
//   background-repeat: no-repeat;
// }
// .preload{
//   position: absolute;
//   top: calc(50% - 75px);
//   left: calc(50% - 75px);
// }
.spinner-border {
  border-color: $font-black;
  border-right-color: transparent
}
</style>