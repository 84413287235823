import Vue from 'vue'
import Vuex from 'vuex'
// import router from '@/router'
// import Cookies from 'js-cookie'
import axios from "axios";
import sbjs from 'sourcebuster';
import { v4 as uuid } from '@lukeed/uuid/secure';


Vue.use(Vuex)
Vue.use(sbjs)
sbjs.init();

const getDefaultState = () => {
  return {
    guid: uuid(),
    latitude: null,
    longitude: null,
    bblId: null,
    //
    area_custom: null,
    streetType_custom: null,
    build_date: null,
    //
    BBLBasicData_status: {
      is_loading: false,
      error: null,
      data: null,
    },
    BBLSearchResults_status: {
      is_loading: false,
      error: null,
      data: null,
    },
    BBLData_status: {
      is_loading: false,
      error: null,
      data: null,
    },
    evaluationResults_status: {
      is_loading: false,
      error: null,
      data: null,
    },
    sendInfo_status: {
      is_loading: false,
      error: null,
      data: null,
    },
  }
}

const saveStateToLocalStorage = (state) => {
  const stateWithTimestamp = {
    ...state,
    timestamp: new Date().toISOString()
  };
  localStorage.setItem('store', JSON.stringify(stateWithTimestamp));
}

const MAX_STATE_AGE_DAYS = 10;

const loadStateFromLocalStorage = () => {
  const state = localStorage.getItem('store');
  if (state) {
    const parsedState = JSON.parse(state);
    const stateAge = (new Date() - new Date(parsedState.timestamp)) / (1000 * 60 * 60 * 24);
    if (stateAge > MAX_STATE_AGE_DAYS) {
      return null;
    }
    return parsedState;
  }
  return null;
}

const store = new Vuex.Store({
  state: loadStateFromLocalStorage() || getDefaultState(),
  getters: {
    get_latitude: state => state.latitude,
    get_longitude: state => state.longitude,

    get_bblId: state => state.bblId,

    get_all: state => state,

    get_area_custom: state => state.area_custom,
    get_build_date: state => state.build_date,

    get_BBLBasicData_status: state => state.BBLBasicData_status,
    get_BBLBasicData: state => state.BBLBasicData_status.data || {},

    get_BBLData_status: state => state.BBLData_status,
    get_BBLData: state => state.BBLData_status.data || {},

    get_BBLSearchResults_status: state => state.BBLSearchResults_status,
    get_BBLSearchResults: state => state.BBLSearchResults_status.data || {},

    get_evaluationResults_status: state => state.evaluationResults_status,
    get_evaluationResults: state => state.evaluationResults_status.data || {},

    get_streetType_custom: state => state.streetType_custom,
    get_need_streetType_custom: state => {
      const specialZoning = ['R6', 'R7-1', 'R7-2', 'R8'],
        zoning = state.BBLData_status.data ? state.BBLData_status.data.zoning : null,
        zoningStreetType = state.BBLData_status.data ? state.BBLData_status.data.zoningStreetType : null
      return specialZoning.includes(zoning)
        && (!zoningStreetType || zoningStreetType === 'mix')
    },
    get_is_low_density: state => state.BBLData_status.data && state.BBLData_status.data.density === 'low',
    get_sendInfo_status: state => state.sendInfo_status,
    get_sendInfo: state => state.sendInfo_status.data || {},
  },
  mutations: {
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
    setGetBBLBasicData_status(state, { is_loading, error, data }) {
      state['BBLBasicData_status'] = { is_loading, error, data };
    },
    setBBLData_status(state, { is_loading, error, data }) {
      state['BBLData_status'] = { is_loading, error, data };
    },
    setBBLSearchResults_status(state, { is_loading, error, data }) {
      state['BBLSearchResults_status'] = { is_loading, error, data };
    },
    setEvaluationResults_status(state, { is_loading, error, data }) {
      state['evaluationResults_status'] = { is_loading, error, data };
    },
    setSendInfo_status(state, { is_loading, error, data }) {
      state['sendInfo_status'] = { is_loading, error, data };
    },
    setBBLId(state, value) {
      state['bblId'] = value;
    },
    setGoogleCords(state, { latitude, longitude }) {
      state['latitude'] = latitude;
      state['longitude'] = longitude;
    },
    setAreaCustom(state, value) {
      // console.log("setAreaCustom", value)
      state['area_custom'] = value || state.area;
    },
    setBuildDate(state, value) {
      // console.log("setBuildDate", value)
      state['build_date'] = value || null;
    },
    setStreetTypeCustom(state, value) {
      console.log("setStreetTypeCustom", value)
      state['streetType_custom'] = value;
    }
  },
  actions: {
    getBBLBasicData({ commit, dispatch }, payload) {
      let params = {
        "block": payload.block,
        "boroughCode": payload.boroughCode,
        "by_address": payload.searchAddress,
        "easement": null,
        "lot": payload.lot,
        "bbleID": payload.bblId,
      };

      let url = 'https://old.portal.mgnyconsulting.com:8091/API/PublicPortal/BBLBasicData'

      commit('setGetBBLBasicData_status', { is_loading: true, error: null, data: null })
      axios.post(url, params)
        .then(response => {
          if (response.status === 200 && response.data) {
            commit('setGetBBLBasicData_status', { is_loading: false, error: null, data: response.data })
            commit('setBBLId', response.data['id'])
            dispatch('getBBLData')
          }
        })
        .catch(error => {
          if (error.response) {
            commit('setGetBBLBasicData_status', { is_loading: false, error: error.message, data: null })
          }
        })
    },
    getBBLData({getters, commit}) {
      const url = `https://api.regtimebuilder.com:5134/getBBLEData/${getters.get_bblId}`

      commit('setBBLData_status', { is_loading: true, error: null, data: null })

      axios.get(url)
        .then(response => {
          if (response.status === 200 && response.data) {
            console.log('getBBLData', response.data)
            // response.data.density = 'low'
            commit('setBBLData_status', { is_loading: false, error: null, data: response.data })
            // commit('setAreaCustom', response.data['landArea'])
          }
        })
        .catch(error => {
          commit('setBBLData_status', { is_loading: false, error: error.message, data: null })
        })
    },
    evaluateResults({ getters, commit }) {
      const params = {
        'bbleId': getters.get_bblId,
        "landAreaTotal": getters.get_area_custom,
        'avgTaxPerSf': null,
        'avBeforeConstruction': null,
        'zoningStreetType': getters.get_streetType_custom || getters.get_BBLData['zoningStreetType'],
      }
      
      console.log('evaluateResults', params)
      const url = `https://api.regtimebuilder.com:5134/evaluate`

      commit('setEvaluationResults_status', { is_loading: true, error: null, data: null })

      axios.post(url, params, { timeout: 5000 })
        .then(response => {

          // response.data.options = response.data.options.filter(item => !['avoid_prev_wages'].includes(item.program))

          commit('setEvaluationResults_status', { is_loading: false, error: null, data: response.data })
        }).catch(err => {
          commit('setEvaluationResults_status', { is_loading: false, error: err.message, data: null })
        })
    },
    getBBLSearchResults({ commit }, payload) {
      let url = `https://old.portal.mgnyconsulting.com:8091/API/PublicPortal/getPropertyAddressAuto/${payload.inputValue}`

      commit('setBBLSearchResults_status', { is_loading: true, error: null })
      axios.get(url)
        .then(response => {
          if (response.status === 200 && response.data) {
            commit('setBBLSearchResults_status', { is_loading: false, error: null, data: response.data })
          }
        })
        .catch(error => {
          commit('setBBLSearchResults_status', { is_loading: false, error: error.message })
        })
    },
    sendInfo({ getters, commit }, params) {
      // if (!getters.get_guid) {
      //   commit('setGuid')
      // }
      let data_send = {
        ...params,
        'FirstSource': sbjs.get.first.src + '-' + sbjs.get.first.mdm + '-' + sbjs.get.first.cmp + '-' + sbjs.get.first.trm,
        'LastSource': sbjs.get.current.src + '-' + sbjs.get.current.mdm + '-' + sbjs.get.current.cmp + '-' + sbjs.get.current.trm,

        ...getters.get_all,
       }
      
      commit('setSendInfo_status', { is_loading: true, error: null, data: null })
      
      axios.post('https://mgny.force.com/sales/services/apexrest/lead/add', data_send)
        .then(response => {
          console.log('SendInfo', response)
          commit('setSendInfo_status', { is_loading: false, error: null, data: response.data })
        })
        .catch(error => {
          console.log('SendInfo', error)
          commit('setSendInfo_status', { is_loading: false, error: error.message, data: null })
        })
    }
  },
  modules: {
  }
})

store.subscribe((mutation, state) => {
  saveStateToLocalStorage(state);
});

export default store;

