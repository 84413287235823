<template>
  <b-modal id="low-density-modal" body-bg-variant="coool" header-bg-variant="coool" header-class="header-class"
    hide-footer size="lg" dialog-class="modal-dialog-bottom" body-class="modal-class"
    @hidden="goBack"
    @ok="goBack"
    >
    <div class="form-wrap">
      <div class="d-flex justify-content-center">
        <div>
          <div class="title-main text-center">
            <div class="title-main-txt">
              Unfortunately, this property is not eligible for UAP due to the low density zoning district.
            </div>
          </div>
        </div>
      </div>
      <div class="wrap-inputs">
        <div class="col-12">
          <div class="lic-less d-flex justify-content-center hand">
            <button @click="goBack" class="help-btn  w-30">Go Back</button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>


export default {
name: "LowDensityModal",
  components: {

  },
  data: () => ({

  }),
  computed: {
  },
  watch:{
  },
  methods:{
    startModal(){
      // this.ConstructionCost = paramz.ConstructionCost;
      // this.ConstructionType= paramz.ConstructionType;
      // this.BBL = paramz.BBL;
      // this.result = paramz.result;

      // this.$bvModal.show('low-density-modal')
      console.log('show low-density-modal modal')
    },
    goBack() {
      console.log('go back')
      this.$bvModal.hide('low-density-modal')
      this.$router.push({ name: 'home' })
    },
  }
}
</script>
<style>
.req-btn{
  width: 360px!important;
}
.wrap-inputs{
  width: 100% !important;
  margin-top: 32px;
}
.not-allowed{
  cursor: not-allowed;
}
.modal-header {
  border-bottom: none!important;
}
.bg-coool{
  background: #FFFFFF;
}
.modal-content {
  border-radius: 10px 10px 10px 10px!important;
}
.modal-class{
  border-radius: 10px!important;
}
.header-class{
  border-radius: 10px!important;
}
.modal-footer > * {
  margin: 0!important;
}
.was-validated .form-control:invalid, .form-control.is-invalid {
  color: #FF2929;
}
.thank_img{
  height: 79.51px;
  width: 103.6px;
  margin-bottom: 52px;
}
.form-control {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
}
.modal-backdrop{
  transition: 0.8s;
  opacity: 0.88!important;
  background-color: rgba(1,2,15,1)!important
}
#low-density-modal___BV_modal_header_{
  display: none;
}
@media screen and (min-width: 768px) and (max-width: 1223px) {
  .close-btn{
    display: none;
  }
  #low-density-modal___BV_modal_header_{
    display: block;
  }
  .modal-dialog {
    max-width: 640px!important;
  }
}
@media screen and (min-width: 320px) and (max-width: 767px) {
  .close-btn{
    display: none;
  }
  #low-density-modal___BV_modal_header_{
    display: none;
  }
}
</style>


<style lang="scss" scoped>
.help-btn{
  width: 240px;
}
.title-main-txt{
  white-space: pre-line;
}
.title-sent{
  margin-bottom: 0;
}
.req-btn{
  width: 360px!important;
}
.wrap-inputs{
  width: 100% !important;
  margin-top: 32px;
}
.title-descr{
  width: 464px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  margin: 0 auto 0;
}
.back-container{
  padding: 20px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.back-btn{
  height: 20px;
  width: 35px;
  color: #4D59EB;
  font-family: "Open Sans";
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;

}
.close-btn{
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #4b4d56;
  box-shadow: 0 2px 4px 0 rgba(26, 41, 60, 0.15);
  position: absolute;
  top: 0;
  right: -300px;
  cursor: pointer;
  z-index: 999;
  padding: 8px 13px;
}
.cross{
  height: 15px;
  width: 15px;
  color: #FFFFFF;
}
.sent-pad{
  padding: 0;
  text-align: center;
}
.err-state-text{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
  position: absolute;
}
.err-state{
  background-color: #FFE9E8!important;
  border: 1px solid #FF7564!important;
  &::placeholder{
    color: #FF7564!important;
    font-family: "Open Sans";
  }
}
.err-state.long{
  width: 350px;
  right: 0px;
}
.err-state::after{
  content: '';
  position: absolute;
  left: 49%;
  top: 100%;
  border-left: 4px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #f60606;
}
::placeholder {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 23px;

  color: #C6C6C6;
}
.mb-32px{
  margin-bottom: 32px;
}
.mb-40px{
  margin-bottom: 40px;
}
.thank{
  width: 109.6px;
  margin-bottom: 50px;
}
.form-wrap{
  padding: 0;
}
.title-main{
  width: 722px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 28px;
  letter-spacing: -0.63px;
  line-height: 49px;
  text-align: center;
  margin: 0 auto 12px;
}
.title-bad{
  width: 536px;
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 24px;
  letter-spacing: 0;
  line-height: 33px;
  text-align: center;
  margin: 0 auto 12px;
}
.help-text-bad{
  width: 321px;
  color: #7F83A7;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin: auto;
}
.help-text{
  width: 416px;
  color: #7F83A7;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  margin: auto;
}
.descr{
  color: #0A1250;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 26px;
  text-align: center;
  margin-bottom: 32px;
}
.text-blue{
  color: #2692F5;
}
.description-wrap{
  padding:30px
}
.cust-inp{
  height: 48px;
  border-radius: 6px;
  border-color: transparent;
  background-color: #F9FAFF;
  font-family: "Open Sans";
  font-size: 15px;
  letter-spacing: 0;
  line-height: 20px;
  color: #0A1250;
  &::placeholder{
    color: #7F83A7;
    font-family: "Open Sans";
    font-size: 15px;
    letter-spacing: 0;
    line-height: 20px;
   }
  &:active{
    box-sizing: border-box;
    height: 48px;
    border: 1px solid #4D59EB;
    border-radius: 6px;
  }
  &:focus{
    box-shadow: none;
    border: 1px solid #4D59EB;
  }
}
.hand{
  cursor: pointer;
}

@media screen and (max-width: 768px) and (max-width: 1223px) {
  .form-wrap{
    margin-top: -48px;
  }
  .req-btn{
    width: 360px!important;
  }
  .title-descr{
    width: 464px;
    color: #0A1250;
    font-family: "Open Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    text-align: center;
    margin: 0 auto 0;
  }
  .wrap-inputs{
    padding: 0;
    width: 100% !important;
    margin-top: 32px;
  }
  .title-main {
    margin: 0 0 12px;
  }
  .title-bad {
    margin: -29px 0 12px;
  }
}
@media screen and (min-width: 415px) and (max-width: 767px) {
  .form-wrap {
    padding: 0;

  }
  .title-main{
    width: auto;
    margin : -30px 0 12px;
    font-size: 24px;
    line-height: 33px;
  }

  .title-main-second {
    white-space: pre-line;
  }

  .wrap-inputs{
    padding: 0;
    width: 100% !important;
    margin-top: 25px;
  }
  .mb-32px{
    margin-bottom: 28px;
  }
  .sent-pad{
    padding: 0 0px 38px;
  }
  .descr{
    margin-bottom: 60px;
    font-size: 24px;
    line-height: 33px;
  }
  .help-text{
    width:288px
  }
  .title-bad{
    width:338px
  }
}

@media screen and (max-width: 415px) {
  .form-wrap {
    padding: 0;
    margin-top: 16px;
  }
  .title-main-txt{
    display: block;
  }
  .title-main-second{
    display: none;
  }
  .title-descr{
    width: 309px;
    font-size: 12px;
    line-height: 17px;
    margin: 0 auto 0;
  }
  .title-main{
    width: 323px;
    margin: -0 auto 12px;
    font-size: 19px;
    line-height: 28px;
  }
  .wrap-inputs{
    padding: 0;
    width: 100% !important;
    margin-top: 25px;
  }
  .mb-32px{
    margin-bottom: 16px;
  }
  .sent-pad{
    padding: 0 0px 18px;
  }
  .descr{
    margin-bottom: 40px;
    font-size: 16px;
    line-height: 22px;
  }
  .help-text{
    width:288px
  }
  .title-bad{
    width:338px
  }
  .title-sent{
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 375px) {
  .form-wrap {
    padding: 0;
    margin-top: 16px;
  }
  .wrap-inputs{
    padding: 0;
    width: 100% !important;
    margin-top: 25px;
  }
  .mb-32px{
    margin-bottom: 16px;
  }
  .sent-pad{
    padding: 0 0px 18px;
  }

  .help-text{
    width:288px
  }
  .title-bad{
    width:338px
  }
  .title-main{
    width: 330px;
    margin: 0 0 12px;
    font-size: 19px;
    line-height: 26px;
  }
  .title-sent{
    margin-bottom: 40px;
  }

  .descr{
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 33px;
  }
}
@media screen and (max-width: 320px) {
  .title-main{
    width: 302px;
    margin: 0 0 12px;
    font-size: 18px;
    line-height: 24px;
  }
  .err-state-text{
    font-size: 10px;
    top:45px
  }
  .form-wrap {
    padding: 0;
    margin-top: 16px;
  }
  .help-text{
    width:288px
  }
  .title-bad{
    width:295px
  }
  .help-text-bad{
    width:288px
  }
  .wrap-inputs{
    padding: 0 1px 15px;
    width: 100% !important;
    margin-top: 15px;
  }
  .mb-32px{
    margin-bottom: 12px;
  }
  .sent-pad{
    padding: 0;
  }
  .descr{
    margin-bottom: 50px;
    width: 230px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;
  }
}

</style>